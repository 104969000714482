
import Cookies from 'js-cookie'
import { mainToken } from './authentication'
var CryptoJS = require('crypto-js')



export const token = Cookies.get('hsbcspringeqrbjt')?CryptoJS.AES.decrypt(Cookies.get('hsbcspringeqrbjt'), 'hsbcspring').toString(CryptoJS.enc.Utf8):'null'
export const customerCode = Cookies.get('hsbcspringeqrbsc')?CryptoJS.AES.decrypt(Cookies.get('hsbcspringeqrbsc'), 'hsbcspring').toString(CryptoJS.enc.Utf8):'null'

export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 

export const email = Cookies.get('hsbcspringeqrbmal')?CryptoJS.AES.decrypt(Cookies.get('hsbcspringeqrbmal'), 'hsbcspring').toString(CryptoJS.enc.Utf8):'null';

 /* 
 export  const ServerUrl = 'http://192.168.64.3/React/equitybank/frontend/controller';
export  const adminImages = 'http://192.168.64.3/React/equitybank/backadmin/images';
export  const PictureUrl = 'http://192.168.64.3/React/equitybank/frontend/images';
export  const FrontEndUrl = 'http://192.168.64.3/React/equitybank/frontend/rb/index.html';   */  




export  const ServerUrlAdmin = 'https://backend.nbsparebank.com/controller';
  export  const ServerUrl = 'https://clients.nbsparebank.com/controller';
export  const adminImages = 'https://backend.nbsparebank.com/images';
export  const PictureUrl = 'https://clients.nbsparebank.com/images';
export  const FrontEndUrl = 'https://nbsparebank.com';  
 
export  const emailAddress = 'support@nbsparebank.com'; 



